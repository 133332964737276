import './TimeLine.scss';
import '../Window.scss';
import WindowBtn from '../../components/WindowBtn/WindowBtn';
import TaskModal from '../../components/Modal/TaskModal';
import { useState } from 'react';
import Calendar from '../../components/Calendar/Calendar';

const TimeLine = props => {
    const [addActivityDisp, setAddActivityDisp] = useState('none');

    return(
        <div className="Window" style={props.style}>
            <div className="window_header">
                <div className="window_header_sx window_header_sx_activity">
                    <p className="window_header_title">Timeline</p>
                    <WindowBtn text="Attività" handleClick={() => setAddActivityDisp('block')}/>
                </div>
            </div>

            <div className="window_calendar_container">
                <Calendar />
            </div>

            <TaskModal display={addActivityDisp} handleClose={() => setAddActivityDisp('none')} id="taskmodal1"/>
        </div>
    );
}

export default TimeLine;