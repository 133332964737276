import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Contact from '../../pages/Contact/Contact.js';
import Drive from '../../pages/Drive/Drive.js';
import TimeLine from '../../pages/TimeLine/TimeLine';
import './PageChanger.scss';

const PageChanger = () => {
    const currentPage = useSelector(state => state.page);
    const [style, setStyle] = useState([{bottom: '0%', opacity: '1'}, {bottom: '100%', opacity: '0'}]);
    const [cont, setCont] = useState(0);

    useEffect(() => {
        setCont(cont + 1);
    }, [currentPage]);

    useEffect(() => {
        if(cont === 2) {
            setStyle([{animation: 'show .5s ease-in forwards'}, {animation: 'hide .5s ease-in forwards'}]);
        }
    }, [cont]);

    return(
        <div className="PageChanger">
            <Drive style={(currentPage === 'Drive')? style[0] : style[1]}/>
            <TimeLine style={(currentPage === 'Timeline')? style[0] : style[1]}/>
            <Contact style={(currentPage === 'Contatti')? style[0] : style[1]} />
        </div>
    );
}

export default PageChanger;