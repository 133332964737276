import {useEffect} from 'react';
import { serverHost } from '../../utils/ServerHost';

const DownloadDir = () => {
    useEffect(() => {
        const dirId = getIdFromURL();

        fetch(serverHost + '/folder/download_all/' + dirId, {credentials: 'include'}).then(res => 
            
            console.log(res));
    }, []);

    const getIdFromURL = () => {
        const path = window.location.pathname;
        const lastSlash = path.lastIndexOf('/');
        let dirId = '';

        for(let i=lastSlash + 1; i< path.length; i++) {
            dirId += path.charAt(i);
        }


        return dirId;
    }

    return(
        <div className="DownloadDir">
            Download in corso...
        </div>
    );
}

export default DownloadDir;