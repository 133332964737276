import { useDispatch } from 'react-redux';
import { serverHost } from '../../utils/ServerHost';
import './Auth.scss';
import {isLoggedAction} from '../../redux/actions/isLogged';
import TextModal from '../Modal/TextModal';
import { useState } from 'react';
import Loader from '../Loader/Loader'

const Login = () => {
    const [errorDisp, setErrorDisp] = useState('none');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const accedi = () => {
        const email = document.getElementById('auth_email').value;
        const pwd = document.getElementById('auth_pwd').value;

        try{
            setLoading(true);

            fetch(serverHost + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    email: email,
                    password: pwd
                })
            })
            .then(res => {
                fetch(serverHost + '/is_logged', {
                    credentials: 'include'
                  }).then(res => {
    
                    if(res.status !== 401 && res.status >= 200 && res.status < 300) {
                        setLoading(false);
    
                      return res.text();
                    }
                    setErrorDisp('block');
                    setLoading(false);
    
                    return false;
                  })
                  .then(email => {
                    setLoading(false);
    
                    dispatch(isLoggedAction('newBool', email));
                  })
                  setLoading(false);
    
            });
        }
        catch(error){
            setLoading(false);
            console.log(error);

        }

    }

    const keyDown = e => {
        console.log(e.code);
        if(e.code === 'Enter') {
            accedi();
        }
    }

    return(
        <div className="Auth">
            {loading &&   <Loader></Loader> }

            <div className="auth_container" onKeyDown={e => keyDown(e)}>
                <p className="auth_title">Area riservata</p>
                <input className="auth_input_text" id="auth_email" placeholder="Inserisci l'email"></input>
                <input className="auth_input_text" type="password" id="auth_pwd" placeholder="Inserisci la password"></input>
                <button className="auth_submit" onClick={() => accedi()}>Accedi</button>
            </div>

            <TextModal text="Email e/o password errati" display={errorDisp} handleClose={() => setErrorDisp('none')}/>
        </div>  
    );
}

export default Login;