import './SideBarBtn.scss';
import PropTypes from 'prop-types';
import SvgColor from 'react-svg-color';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { pageAction } from '../../redux/actions/page';

const SideBarBtn = props => {
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.page);

    const [color, setColor] = useState('#000');
    const [leftlineOpacity, setLeftlineOpacity] = useState('0');

    useEffect(() => {
        if(currentPage === props.text) {
            setColor('#3D70ED');
            setLeftlineOpacity('1');
        } else {
            setColor('#000');
            setLeftlineOpacity('0');
        }
    }, [currentPage])

    return(
        <div className="SideBarBtn" onClick={() => dispatch(pageAction(props.text))}>
            <div className="sidebar_btn_leftline" style={{opacity: leftlineOpacity}}></div>
            <div className="sidebar_btn_icon" >
                <SvgColor 
                    svg={props.icon} 
                    width={20} 
                    colors={[color, color, color, color, color]}   
                />
            </div>
            <p className="sidebar_btn_text" style={{color: color}}>{props.text}</p>
        </div>
    );
}

SideBarBtn.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default SideBarBtn;