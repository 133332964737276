import './ImageShower.scss';
import { useDispatch, useSelector } from 'react-redux';
import { serverHost } from '../../utils/ServerHost';
import { useRef } from 'react';
import { imageAction } from '../../redux/actions/image';
import { Lightbox } from "react-modal-image";

const ImageShower = () => {
    const image = useSelector(state => state.image);

    const showerRef = useRef();

    const dispatch = useDispatch();

    const close = () => {
        dispatch(imageAction(image.id, 'none'));
    }

    return(
        <div className="ImageShower" style={{display: image.display}} ref={showerRef}>
            <Lightbox
                large={serverHost + '/media/show/' + image.id}
                alt="PREVIEW"
                onClose={close}
            />
        </div>
    )
}

export default ImageShower;