import './Header.scss';

import menu from '../../img/menu.svg';
import {useDispatch} from 'react-redux';
import {openSideBarAction} from '../../redux/actions/openSideBar';
import { useEffect, useState } from 'react';
import { serverHost } from '../../utils/ServerHost';
import Notifications from '../Notifications/Notifications';

const Header = () => {
    const [listDisp, setListDisp] = useState({width: '0px', height: '0px', fontSize: '0px'});
    const [btnClicked, setBtnClicked] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        fetch(serverHost + '/name', {credentials: 'include'})
        .then(res => res.json())
        .then(json => {
            setNome(json.nome);
            setCognome(json.cognome);
        })
    }, []);

    useEffect(() => {
        if(btnClicked) {
            setListDisp({width: '120px', height: '55px', fontSize: '16px', border: '1px solid #e2e2ea'});
        } else {
            setListDisp({width: '0px', height: '0px', fontSize: '0px', border: '0px solid #e2e2ea'});
        }
    }, [btnClicked]);

    const logout = () => {
        setBtnClicked(!btnClicked);

        fetch(serverHost + '/logout', {credentials : 'include'}).then(res => {
            fetch(serverHost + '/is_logged', {
                credentials: 'include'
            }).
            then(res => {
                console.log(res.status);
                if(res.status === 401) {
                    window.location.reload();
                }
            });
        })
    }

    return(
        <div className="Header">
            <div className="header_sx">
                <img className="header_sx_open_menu" src={menu} alt="open_menu" width="24px" onClick={() => dispatch(openSideBarAction('open', true))}></img>
                <p className="header_sx_title">Dashboard</p>
            </div>

            <div className="header_dx">
                <Notifications />
                <button className="header_dx_profile" onClick={() => setBtnClicked(!btnClicked)}>{nome.charAt(0).toUpperCase()}{cognome.charAt(0).toUpperCase()}</button>
                <div className="header_dx_profile_list" style={listDisp}>
                    <p className="header_dx_profile_list_text" onClick={() => logout()}>Logout</p>
                </div>
            </div>
        </div>
    );
}

export default Header;