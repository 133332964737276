import { serverHost } from "../utils/ServerHost";

export async function saveContact(agencyName, referente, number, email, note, folderId) {
    return await fetch(serverHost + '/contact/', {
        method: "POST", 
        credentials : 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            agencyName: agencyName,
            referente: referente, 
            number: number,
            email: email, 
            note: note, 
            folderId: folderId
        })
    }).then(resp => resp.json()).then(json => json);
}

export async function getContact(id) {
    return await fetch(serverHost + '/contact/' + id, {credentials: 'include'})
    .then(res => res.json())
    .then(json => json);
}

export async function deleteContact(id) {
    const del = await fetch(serverHost + '/contact', {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify({
            id: id
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res);

    return del;
}

export async function patchContact(agencyName, referente, number, email, note, id) {
    return await fetch(serverHost + '/contact/', {
        method: "PATCH", 
        credentials : 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            agencyName: agencyName,
            referente: referente, 
            number: number,
            email: email, 
            note: note, 
            id: id
        })
    }).then(resp => resp.json()).then(json => json);
}

export async function changeContactFolder(contactId, folderId, newFolderId) {
    const resp = await fetch(serverHost + '/contact/changeFolder', {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify({
            contactId: contactId,
            folderId: folderId,
            newFolderId: newFolderId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res.resp);

    return resp;
}

export async function getAllContact() {
    const contact = await fetch(serverHost + '/contact/get/all', {credentials: 'include'})
    .then(res => res.json())
    .then(contacts => contacts);

    return contact;
}