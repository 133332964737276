import {combineReducers} from 'redux';
import currentFolderReducer from './currentFolder';
import openSideBarReducer from './openSideBar';
import searchedReducer from './searched';
import isLoggedReducer from './isLogged';
import socketReducer from './socket';
import dragAndDropReducer from './dragAndDrop';
import pageReducer from './page';
import calendarReducer from './calendar';
import dropDownReducer from './dropDown';
import filterItemReducer from './filterItem';
import pdfReducer from './pdf';
import imageReducer from './image';

const rootReducer = combineReducers({
    currentFolder: currentFolderReducer,
    searched: searchedReducer,
    openSideBar: openSideBarReducer,
    isLogged: isLoggedReducer,
    socket: socketReducer,
    dragAndDrop: dragAndDropReducer,
    page: pageReducer,
    calendar: calendarReducer,
    dropDown: dropDownReducer,
    filterItem: filterItemReducer,
    pdf: pdfReducer,
    image: imageReducer
});

export default rootReducer;