import {serverHost} from '../utils/ServerHost';
import fileDownload from 'js-file-download';

export async function getFile(id) {
    const file = await fetch(serverHost + '/file/' + id, {
        credentials: 'include'
    })
    .then(res => res.json())
    .then(res => res);

    return file;
}

export async function saveFile(file, folderId) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("folderId", folderId);

    const saved = await fetch(serverHost + '/file/', {method: "POST", credentials: 'include', body: formData})
    .then(res => res.text())
    .then(res => res);

    return saved;
}

export async function downloadFile(id, renameToDownload) {
    const download = await fetch(serverHost + "/file/download/" + id, {credentials: 'include'})
    .then(res => res.blob())
    .then(blob => fileDownload(blob, renameToDownload));

    return download;
}

export async function updateFileName(id, newName, parentId) {
    const update = fetch(serverHost + '/file', {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify({
            id: id,
            newName: newName,
            parentId: parentId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res);

    return update;
}

export async function deleteFile(id) {
    const del = await fetch(serverHost + '/file', {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify({
            id: id
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res);

    return del;
}

export async function changeFileFolder(fileId, folderId, newFolderId, fileSize) {
    const resp = await fetch(serverHost + '/file/cambia/changeFolder', {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify({
            fileId: fileId,
            folderId: folderId,
            newFolderId: newFolderId,
            fileSize: fileSize
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res.resp);

    return resp;
}