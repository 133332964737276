const pageReducer = (state = 'Drive', action) => {
    switch(action.type)
    {
        case 'changePage':
            state = action.pageName;
            return state;
        default :
            return state;
    }
}

export default pageReducer;