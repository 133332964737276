import SideBarBtn from '../SideBarBtn/SideBarBtn';
import './SideBar.scss';
import {useEffect, useState} from 'react';

import logo from '../../img/logo_1.svg';
import close from '../../img/closegray.svg';
import contactIcon from '../../img/contact_menu.svg';

import driveIcon from '../../img/drive.svg';
import timelineIcon from '../../img/calendar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { openSideBarAction } from '../../redux/actions/openSideBar';
const SideBar = () => {
    const [width, setWidth] = useState('0px');

    const openMobile = useSelector(state => state.openSideBar);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(window.innerWidth <= 1060) {
            setWidth('0px');
        } else {
            setWidth('250px');
        }
    }, []);

    useEffect(() => {
        if(openMobile) {
            setWidth('250px');
        } else {
            setWidth('0px');
        }
    }, [openMobile]);

    return(
        <div className="sb">
            <style>
                {
                    `.sb{
                        width: 250px;
                        height: 10px;
                        position: relative;
                        transition: width .3s ease-in;
                        z-index: 100;
                    }
                    @media screen and (max-width: 1060px) {
                        .sb{
                            width: ${width};
                            position: fixed;
                            top: 0;
                            left: 0;
                        }
                    }
                    `
                }
            </style>
            <div className="SideBar">
                <div className="sidebar_header">
                    <img className="sidebar_header_close" src={close} alt="close_menu" onClick={() => dispatch(openSideBarAction('open', false))}></img>
                    <img className="sidebar_header_logo" src={logo} alt="logo" width="112px"></img>
                    <img className="sidebar_header_logo2" src={logo} alt="logo" width="112px"></img>
                </div>

                <div className="sidebar_btn_container">
                    <SideBarBtn icon={driveIcon} text="Drive"/>
                    <SideBarBtn icon={timelineIcon} text="Timeline"/>
                    <SideBarBtn icon={contactIcon} text="Contatti"/>
                </div>
            </div>
        </div>
    );
}

export default SideBar;