import './Modal2.scss';

import contactIcon from '../../img/Contact.svg';
import closeIcon from '../../img/closeBlue.svg';
import { useEffect, useState } from 'react';

const ContactModalShow = props => {
    const [noteDisp, setNoteDisp] = useState('none');
    const [display, setDisplay] = useState('none');
    const [modalAnim, setModalAnim] = useState();
    const [animation, setAnimation] = useState();

    useEffect(() => {
        if(props.display === 'none') {
            setAnimation('hideBG .25s ease-in forwards');
            setModalAnim('hideModal .25s ease-in forwards');
            setTimeout(() => setDisplay('none'), 300);
        } else {
            setDisplay(props.display);
            setAnimation('showBG .25s ease-in forwards');
            setModalAnim('showModal .25s ease-in forwards');
        }
    }, [props.display]);

    useEffect(() => {
        document.getElementById('modal2AgencyName' + props.id).value = props.agencyName;
        document.getElementById('modal2Referente' + props.id).value = props.referente;
        document.getElementById('modal2contactNumber' + props.id).value = props.number;
        document.getElementById('modal2contactEmail' + props.id).value = props.email;
        document.getElementById('modal2contactNote' + props.id).value = props.note;

        if(props.note) {
            setNoteDisp('block');
        } else {
            setNoteDisp('none');
        }
    }, [props.agencyName, props.referente, props.number, props.email, props.note, props.id]);
   
    return(
        <div className="Modal2" onClick={e => e.stopPropagation()} style={{display: display, animation: animation}}>
            <div className="modal2_container" style={{animation: modalAnim}}>
                <div className="modal2_container_header">
                    <div className="modal2_col">
                        <img src={contactIcon} alt="contact" height="100%"></img>
                    </div>
                    <img className="modal2_close_icon" src={closeIcon} alt="close" height="28px" onClick={() => props.handleClose()}></img>
                </div>

                <div className="modal2_container_body">
                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Nome azienda</div>
                            <input className="modal2_contact_input_text" id={"modal2AgencyName" + props.id} style={{background: 'transparent', padding: '0'}} readOnly></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Referente</div>
                            <input className="modal2_contact_input_text" id={"modal2Referente" + props.id} style={{background: 'transparent', padding: '0'}} readOnly></input>
                        </div>
                    </div>

                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Numero</div>
                            <input className="modal2_contact_input_text" id={"modal2contactNumber" + props.id} style={{background: 'transparent', padding: '0'}} readOnly></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Indirizzo e-mail</div>
                            <input className="modal2_contact_input_text" id={"modal2contactEmail" + props.id} style={{background: 'transparent', padding: '0'}} readOnly></input>
                        </div>
                    </div>
                    <div className="modal2_contact_text" style={{display: noteDisp}}>Note</div>
                    <textarea className="modal2_contact_textarea" id={"modal2contactNote" + props.id} style={{background: 'transparent', padding: '0', display: noteDisp}}></textarea>
                </div>
            </div>
        </div>
    );
}

export default ContactModalShow;