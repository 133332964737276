import { serverHost } from "../utils/ServerHost";

export async function getFolder(id) {
    const folder = await fetch(serverHost + '/folder/' + id, {
        credentials: 'include'
    })
    .then(res => res.json())
    .then(json => json);

    return folder;
}

export async function createFolder(name, parentId) {
    const newFolder = await fetch(serverHost + '/folder/', {
        method: "POST", 
        credentials : 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name : name,
            parentId: parentId
        })
    });

    return newFolder;
}

export async function renameFolder(newName, id, parentId) {
    const rename = await fetch(serverHost + '/folder', {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify({
            id: id,
            newName: newName,
            parentId: parentId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => json);

    return rename;
}

export async function deleteFolder(id) {
    const del = await fetch(serverHost + '/folder', {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify({
            id: id
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => res);

    return del;
}