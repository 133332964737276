import { useEffect, useState } from 'react';
import  './Calendar.scss';

const CalendarDay = props => {
    const giorni = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
    const [dayNumber, setDayNumber] = useState('');
    const [dayName, setDayName] = useState('');
    const [style, setStyle] = useState({background: 'transparent', color: '#171725'});

    useEffect(() => {
        const dateNow = new Date();
        const date = new Date(props.year, props.month, props.day);

        setDayNumber(date.getDate());
        setDayName(giorni[date.getDay()]);

        if(dateNow.getDate() === date.getDate() && dateNow.getMonth() === date.getMonth() && date.getFullYear() === date.getFullYear()) {
            setStyle({background: '#0062FF', color: '#fff'});
        }
    }, []);

    return(
        <div className="CalendarDay" style={{width: props.width}}>
            <div className="calendar_day_container" style={style}>
                <div style={{margin: 'auto'}}>
                    <div className="calendar_day_container_text">{dayNumber}</div>
                    <div className="calendar_day_container_text">{dayName}</div>
                </div>
            </div>
        </div>
    );
}

export default CalendarDay;