const searchedReducer = (state = "", action) => {
    switch(action.type)
    {
        case 'cambiaStringa':
            state = action.stringa;
            return state;
        default :
            return state;
    }
}

export default searchedReducer;