import { useEffect, useState } from "react";
import {colors, getRandomInt} from '../../utils/utils';

const SelectBoxItem = props => {
    const [circleColor, setCircleColor] = useState('');
    const [nameInitials, setNameInitials] = useState('');

    useEffect(() => {
        if(!props.item.icon) {
            setCircleColor(colors[getRandomInt(0,5)]);
            if(props.item.name) setNameInitials(props.item.name.charAt(0).toUpperCase() + props.item.cog.charAt(0).toUpperCase());
        }
    }, [props.item])

    return(
        <div className="select_box_list_body_item">
            <div key={props.item.id} className="select_box_list_body_item" onClick={() => props.handleClick(props.item.nome, props.item.id)}>
                <div style={{width: '26px', height: '100%', marginRight: '5px'}}>
                    {
                        (props.item.icon)? 
                        <img className="select_box_list_body_item_img" src={props.item.icon} alt={props.item.type}></img> 
                        : <button className="select_box_list_body_item_circle" style={{background: circleColor}}>{nameInitials}</button>
                    }
                </div>
                {props.item.nome}
            </div>
        </div>
    );
}

export default SelectBoxItem;