const socketReducer = (state = null, action) => {
    switch(action.type)
    {
        case 'changeRef':
            state = action.ref;
            return state;
        default :
            return state;
    }
}

export default socketReducer;