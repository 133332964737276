import './Modal2.scss';

import contactIcon from '../../img/Contact.svg';
import closeIcon from '../../img/closeBlue.svg';
import { useEffect, useState } from 'react';
import WindowBtn2 from '../WindowBtn/WindowBtn2';
import { patchContact } from '../../requests/contact';
import { useDispatch, useSelector } from 'react-redux';
import { getFolder } from '../../requests/folder';
import { currentFolderAction } from '../../redux/actions/currentFolder';

const ContactModalModify = props => {
    const [errorDisp, setErrorDisp] = useState('none');
    const currentFolder = useSelector(state => state.currentFolder);

    const dispatch = useDispatch();

    useEffect(() => {
        document.getElementById('modal2AgencyName' + props.id).value = props.agencyName;
        document.getElementById('modal2Referente' + props.id).value = props.referente;
        document.getElementById('modal2contactNumber' + props.id).value = props.number;
        document.getElementById('modal2contactEmail' + props.id).value = props.email;
        document.getElementById('modal2contactNote' + props.id).value = props.note;
    }, [props.agencyName, props.referente, props.number, props.email, props.note, props.id]);

    const modifyContact = () => {
        const agencyName = document.getElementById('modal2AgencyName' + props.id);
        const referente = document.getElementById('modal2Referente' + props.id);
        const numero = document.getElementById('modal2contactNumber' + props.id);
        const email = document.getElementById('modal2contactEmail' + props.id);
        const note = document.getElementById('modal2contactNote' + props.id);

        if(!agencyName.value || !referente.value || !numero.value || !email.value) {
            setErrorDisp('block');
        } 
        else {
            patchContact(agencyName.value, referente.value, numero.value, email.value, note.value, props.idContact)
            .then(res => {
                props.handleModify(agencyName.value, referente.value, email.value, numero.value, note.value);
                setErrorDisp('none');        
                props.handleClose();
            });
        }
    }
   
    return(
        <div className="Modal2" onClick={e => e.stopPropagation()} style={{display: props.display}}>
            <div className="modal2_container">
                <div className="modal2_container_header">
                    <div className="modal2_col">
                        <img src={contactIcon} alt="contact" height="100%"></img>
                    </div>
                    <img className="modal2_close_icon" src={closeIcon} alt="close" height="28px" onClick={() => props.handleClose()}></img>
                </div>

                <div className="modal2_container_body">
                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Nome azienda</div>
                            <input className="modal2_contact_input_text" id={"modal2AgencyName" + props.id} placeholder="Nome azienda"></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Referente</div>
                            <input className="modal2_contact_input_text" id={"modal2Referente" + props.id} placeholder="Referente"></input>
                        </div>
                    </div>

                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Numero</div>
                            <input className="modal2_contact_input_text" id={"modal2contactNumber" + props.id} placeholder="Numero"></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Indirizzo e-mail</div>
                            <input className="modal2_contact_input_text" id={"modal2contactEmail" + props.id} placeholder="Indirizzo e-mail"></input>
                        </div>
                    </div>
                    <div className="modal2_contact_text">Note</div>
                    <textarea className="modal2_contact_textarea" id={"modal2contactNote" + props.id} placeholder="Note"></textarea>
                    <div className="modal2_contact_error" style={{display: errorDisp}}>Errore, compila tutti i campi!</div>
                    <div className="modal2_contact_footer">
                        <WindowBtn2 text="Modifica contatto" handleClick={modifyContact}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactModalModify;