import './WindowItem.scss';
import PropTypes from 'prop-types';

import icon from '../../img/File.svg';
import opt from '../../img/pall.svg';

import { useEffect, useState } from 'react';
import RidenominaModal from '../Modal/RidenominaModal';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../Modal/DeleteModal';
import { currentFolderAction } from '../../redux/actions/currentFolder';
import { changeFileFolder, deleteFile, getFile, updateFileName } from '../../requests/file';
import { separateNameAndExt } from '../../utils/utils';
import { getFolder } from '../../requests/folder';
import { serverHost } from '../../utils/ServerHost';
import { dragAndDropAction } from '../../redux/actions/dragAndDrop';
import TextModal from '../Modal/TextModal';
import { dropDownAction } from '../../redux/actions/dropDown';
import { pdfAction } from '../../redux/actions/pdf';
import { imageAction } from '../../redux/actions/image';
import { Animated } from "react-animated-css";

const FileItem = props => {
    const [file, setFile] = useState({ size: 0 });
    const [ext, setExt] = useState('');
    const [name, setName] = useState('');
    const [disp, setDisp] = useState('none');
    const [clickedOPT, setClickedOpt] = useState(false);
    const [showList, setShowList] = useState({ width: "0px", fontSize: "0px" });
    const [renameDisp, setRenameDisp] = useState('none');
    const parentId = useSelector(state => state.currentFolder._id);
    const [deleteDisp, setDeleteDisp] = useState('none');
    const [errDisp, setErrDisp] = useState('none');
    const [dropdownOpen, setDropodownOpen] = useState(false);
    const extForFile = ['pdf', 'png', 'jpg', 'jpeg'];

    const dispatch = useDispatch();
    const currentFolder = useSelector(state => state.currentFolder);

    const drag = useSelector(state => state.dragAndDrop);
    const dropDown = useSelector(state => state.dropDown);

    useEffect(() => {
        if (dropDown === props.id) {
            setClickedOpt(true);
        } else {
            setClickedOpt(false);
        }
    }, [dropDown]);

    useEffect(() => {
        if (drag.isDrag !== true) {
            const item = document.getElementById(props.id);
            if(drag.folderId === null) {
                if(item.style.opacity > 0 || !item.style.opacity) {
                    item.style.cssText=`position:relative;margin: 20px 20px 0px 0px;top:0;left:0;display:${disp};`;
                }
            } else if (drag.itemId === props.id) {
                item.style.transition = 'all 0.25s ease-in';
                item.style.top = drag.folderPos.top - 35 + "px";
                item.style.left = drag.folderPos.left - 35 + "px";
                item.style.transform = 'scale(0.3)';
                item.style.opacity = '0';
                console.log(drag);
                changeFileFolder(props.id, file.folderId, drag.folderId, file.size).then(res => {
                    console.log(res);
                    if (!res) {
                        setErrDisp('block');
                        item.style.cssText=`position:relative;margin: 20px 20px 0px 0px;z-index:0;top:0;left:0;display:${disp};`;
                    } else {
                        props.handleUpdateFolder();
                    }
                });
            }
        }
    }, [drag]);

    useEffect(() => {
        getFile(props.id).then(res => {
            setFile(res);
            if(res.folderId === currentFolder._id) {
                setDisp('block');
            }
        });
    }, [props.id]);

    useEffect(() => {
        setDisp('none');
    }, [currentFolder]);

    useEffect(() => {
        if(file.name)
        {
            const arr = separateNameAndExt(file.name);
            setExt(arr[1]);
            setName(arr[0]);
        }
    }, [file]);

    useEffect(() => {
        if(clickedOPT) {
            setShowList({width: "170px", fontSize: "16px"});
        }
        else {
            setDropodownOpen(false);
            setShowList({ width: "0px", fontSize: "0px" });
        }
    }, [clickedOPT]);

    const renameClick = () => {
        dispatch(dropDownAction(null));
        setRenameDisp('block');
        setDropodownOpen(false)

    }

    const deleteClick = () => {
        setDropodownOpen(false)

        dispatch(dropDownAction(null));
        setDeleteDisp('block');
    }

    const optClick = e => {
        if (dropDown === props.id) {
            console.log('chiuso')
            dispatch(dropDownAction(null));
            setDropodownOpen(false)


        } else {
            setDropodownOpen(true)
            console.log('aperto')

            dispatch(dropDownAction(props.id));
        }
        e.stopPropagation();
    }

    const download = e => {
        window.open(serverHost + '/file/download/' + file._id);
    }

    const rename = newName => {
        updateFileName(file._id, newName + "." + ext, parentId)
            .then(res => {
                if (res.resp) {
                    const temp = { ...file };
                    temp.name = newName + '.' + ext;
                    setFile(temp);
                }
            });
    }

    const sposta = e => {
        dispatch(dragAndDropAction(null, true, null, props.id));
        document.addEventListener('mouseup', relaseItem, { once: true });
        document.addEventListener('mousemove', followMouse);
    }

    const followMouse = e => {
        const item = document.getElementById(props.id);
        item.style.cssText=`position:fixed;top:${e.clientY}px;left:${e.clientX}px;z-index:10000;margin:0px;display:${disp};`;
    }

    const relaseItem = () => {
        document.removeEventListener('mousemove', followMouse);
        document.removeEventListener('mouseup', relaseItem);
    }

    const del = () => {
        deleteFile(file._id)
            .then(res => {
                if (res.resp) {
                    getFolder(currentFolder._id).then(res => {
                        dispatch(currentFolderAction('cambiaCartella', {}));
                        dispatch(currentFolderAction('cambiaCartella', res));
                        setDeleteDisp('none');
                    });
                }
            });
    }

    const showFile = () => {
        if (!dropdownOpen) window.open(serverHost + '/media/show/' + file._id);
    }

    const openFile = () => {
        console.log(ext);
        if (ext === 'pdf') {
            dispatch(pdfAction(file._id, 'block'));
        } else {
            dispatch(imageAction(file._id, 'block'));
        }
    }

    return (
        <>
            <div className="WindowItem" id={props.id} onMouseDown={e => sposta(e)} onClick={e => showFile(e)} style={{ display: disp }}>
                <div className="window_item_svg">
                    <img className="window_item_svg_icon" src={icon} alt="item_icon"></img>
                    <div className="window_item_row">
                        <p className="window_item_row_text">{name}</p>
                    </div>

                    <div className="window_item_row">
                        <p className="window_item_row_text2">{ext} · {file.size.toString().substring(0, 3)} Mb</p>
                    </div>
                </div>

                <img className="window_item_opt" id="windowItemOpt" src={opt} alt="options" onClick={e => optClick(e)}></img>
                <div className="window_item_opt_list" id="windowItemOptList" style={showList}>
                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn4" onClick={() => openFile()} style={{ display: (extForFile.indexOf(ext) >= 0) ? 'block' : 'none' }}>Anteprima</p>
                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn3" onClick={(e) => download(e)}>Scarica</p>

                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn2" onClick={() => renameClick()}>Rinomina</p>
                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn1" onClick={() => deleteClick()}>Elimina</p>

                </div>


            </div>
            <RidenominaModal handleClose={() => setRenameDisp('none')} display={renameDisp} placeholder={name} handle={rename} id={file.name} />
            <DeleteModal handleClose={() => setDeleteDisp('none')} display={deleteDisp} handle={del} />
            <TextModal text="Il file già esiste nella cartella" display={errDisp} handleClose={() => setErrDisp('none')} />
        </>
    )
}

FileItem.propTypes = {
    id: PropTypes.string.isRequired
}

export default FileItem;