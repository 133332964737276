import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calendarAction } from '../../../redux/actions/calendar';
import './CalendarFilter.scss';

import caretIcon from '../../../img/caret_down_2.svg';

const CalendarFilter = () => {
    const [filterName, setFilterName] = useState();
    const [iconRotate, setIconRotate] = useState('rotate(0deg)');
    const [isClicked, setIsClicked] = useState(false);
    const [listDisp, setListDisp] = useState({height: '0px', width: '0px', border: '0px solid #E2E2EA', fontSize: '0px'});

    const calendar = useSelector(state => state.calendar);
    const dispatch = useDispatch();

    useEffect(() => {
        if(calendar) {
            switch(calendar.filter) {
                case 'day': setFilterName('Giorno'); break;
                case 'week': setFilterName('Due Settimane'); break;
                case 'month': setFilterName('Mese'); break;
            }
        }
    }, [calendar]);

    useEffect(() => {
        if(isClicked) {
            setIconRotate('rotate(180deg)');
            setListDisp({height: '100px', width: '122px', border: '1px solid #E2E2EA', fontSize: '14px'});
        } else {
            setIconRotate('rotate(0deg)');
            setListDisp({height: '0px', width: '0px', border: '0px solid #E2E2EA', fontSize: '0px'});
        }
    }, [isClicked]);

    const changeFilter = filter => {
        if(calendar) {
            dispatch(calendarAction(calendar.day, calendar.month, calendar.year, filter));
        }

        setIsClicked(false);
    }

    return(
        <div className="CalendarFilter">
            <div className="calendar_filter_text">Vedi:</div>
            <div className="calendar_filter_text_selected" onClick={() => setIsClicked(!isClicked)}>&nbsp;{filterName}</div>
            <img className="caledar_filter_icon" src={caretIcon} alt="caret" onClick={() => setIsClicked(!isClicked)} style={{transform: iconRotate}}></img>

            <div className="calendar_filter_list" style={listDisp}>
                <div className="calendar_filter_list_text" onClick={() => changeFilter('day')}>Giorno</div>
                <div className="calendar_filter_list_text" onClick={() => changeFilter('week')}>Due Settimane</div>
                <div className="calendar_filter_list_text" onClick={() => changeFilter('month')}>Mese</div>
            </div>
        </div>
    );
}

export default CalendarFilter;