import  './Calendar.scss';
import {useEffect, useState} from 'react';
import CalendarDay from './CalendarDay';
import { useSelector } from 'react-redux';
import { getActivitiesInDate } from '../../requests/activity';
import CalendarTask from './CalendarTask/CalendarTask';

const CalendarPage = () => {
    const [pageWidth, setPageWidth] = useState(0);
    const [colWidth, setColWidth] = useState();
    const [dayInPage, setDayInPage] = useState(0);
    const [arrDay, setArrDay] = useState([]);
    const [activities, setActivities] = useState([]);

    const calendar = useSelector(state => state.calendar);

    //setto PageWidth che è la grandezza del componente calendar
    useEffect(() => {
        const calendarPage = document.getElementById('calendarPage');
        setPageWidth(calendarPage.offsetWidth);

        window.addEventListener('resize', () => {
            setPageWidth(calendarPage.offsetWidth);
        });
    }, []);

    //setto colWidth che è la grandezza di una singola colonna
    useEffect(() => {
        if(calendar) {
            switch(calendar.filter) {
                case 'day': setColWidth(pageWidth); break;
                case 'week': setColWidth(90); break;
                case 'month': setColWidth(50); break;
                default: setColWidth(90); break;
            }
        }
    }, [calendar, pageWidth]);

    //setto DayInPage che mi dice quanti giorni sono visibili all'interno del calendario
    useEffect(() => {
        if(pageWidth !==0 && colWidth !==0) {
            setDayInPage(Math.trunc((pageWidth/colWidth) + 0.0001));
        }
    }, [pageWidth, colWidth]);

    useEffect(() => {
        const arr = [];

        for(let i = 0; i < dayInPage; i++) {
            arr.push({day: calendar.day + i, month: calendar.month, year: calendar.year});
        }
        setArrDay([]);
        setArrDay(arr);
    }, [dayInPage, calendar]);

    //prelevo i task dal db
    useEffect(() => {
        if(arrDay) {
            const len = arrDay.length;

            if(len > 0) {
                const startDate = new Date(arrDay[0].year, arrDay[0].month, arrDay[0].day);
                const endDate = new Date(arrDay[len - 1].year, arrDay[len - 1].month, arrDay[len - 1].day);

                getActivitiesInDate(startDate, endDate).then(tasks => setActivities(tasks));
            }
        }
    }, [arrDay]);

    return(
        <div className="CalendarPage" id="calendarPage">
            <div className="calenda_page_header" id="calendarPageHeader">
                {
                    arrDay.map(el => <CalendarDay key={el.day + el.month + el.year} day={el.day} month={el.month} year={el.year} width={colWidth}/>)
                }
            </div>
            <div className="calendar_page_task_container">
                {
                    activities.map(task => <CalendarTask key={task._id} task={task} width={colWidth} id={task._id}/>)
                }
            </div>
        </div>
    );
}

export default CalendarPage;