import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Calendar.scss';
import CalendarFilter from './CalendarFilter/CalendarFilter';
import CalendarPageChanger from './CalendarPageChanger/CalendarPageChanger';

const CalendarHeader = () => {
    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    const calendar = useSelector(state => state.calendar);

    const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

    useEffect(() => {
        if(calendar) {
            const date = new Date(calendar.year, calendar.month, calendar.day);

            setMonth(mesi[date.getMonth()]);
            setYear(date.getFullYear());
        }
    }, [calendar]);

    return(
        <div className="CalendarHeader">
            <div className="calendar_header_sx">
                <div className="calendar_header_sx_text">{month} {year}</div>
            </div>

            <div className="calendar_header_dx">
                <CalendarFilter />
                <div className="calendar_header_dx_today">Oggi</div>
                <CalendarPageChanger />
            </div>
        </div>  
    );
}

export default CalendarHeader;