import './NotifyModal.scss';

import inboxIcon from '../../img/inbox.png';
import closeIcon from '../../img/closegray.svg';
import watchIcon from '../../img/orologio.svg';
import { useEffect, useState } from 'react';
import { addZeroToDateNumber } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { getActivity } from '../../requests/activity';

const NotifyModal = props => {
    const [opacity, setOpacity] = useState('0');
    const [height, setHeight] = useState('0px');
    const [marginBot, setMarginBot] = useState('0px');
    const [padding, setPadding] = useState('0px');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [data, setData] = useState('');
    const [ora, setOra] = useState('');

    const socket = useSelector(state => state.socket);
    const myEmail = useSelector(state => state.isLogged);
  
    useEffect(() => {
        if(props.start) {
            setOpacity('1');

            setTimeout(() => {
                setHeight('110px');
                setMarginBot('15px');
                setPadding('5px 15px 5px 15px');
            }, 50);
        }
        else {
            setOpacity('0');

            setTimeout(() => {
                setHeight('0px');
                setMarginBot('0px');
                setPadding('0px');
            }, 50);
        }
    }, [props.start]);

    useEffect(() => {
        switch(props.type) {
            case 'file':
                setTitle('Nuovo file caricato');
                setDesc(props.email + ' ha caricato un file ' + data + ora);
                break;
            case 'cartella':
                setTitle('Nuova cartella creata');
                setDesc(props.email + ' ha creato una cartella ' + data + ora);
                break;
            case 'contatto':
                setTitle('Nuovo contatto creato');
                setDesc(props.email + ' ha creato un contatto ' + data + ora);
                break;
            case 'activity':
                getActivity(props.itemId).then(act => {
                    setTitle('Nuova attività creata');

                    if(act) {
                        const monthArr = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
                        const startDate = new Date(act.startDate);
                        const endDate = new Date(act.endDate);
                        setDesc("Hai una nuova attività da svolgere tra " + startDate.getDate() + " " + monthArr[startDate.getMonth()] +" e " + endDate.getDate() + " " + monthArr[endDate.getMonth()]);
                    } else {
                        deleteNotify();
                    }
                });
                break;
        }
    }, [props.type, data, ora, props.email]);

    useEffect(() => {
        const dateNow = new Date();
        const notifyDate = new Date(props.timestamp);
        const monthArr = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];

        if(dateNow.getDate() === notifyDate.getDate() && dateNow.getMonth() === notifyDate.getMonth()) {
            setData('oggi');
        } else if(dateNow.getDate() === notifyDate.getDate() + 1 && dateNow.getMonth() === notifyDate.getMonth()) {
            setData('ieri');
        } else {
            setData('il ' + notifyDate.getDate() + ' ' + monthArr[notifyDate.getMonth()]);
        }

        setOra(' alle ' + addZeroToDateNumber(notifyDate.getHours()) + ':' + addZeroToDateNumber(notifyDate.getMinutes()));

    }, [props.timestamp]);

    const deleteNotify = () => {
        setOpacity('0');
        setTimeout(() => {
            setHeight('0px');
            setMarginBot('0px');
            setPadding('0px');
        }, 800);

        socket.emit('deleteNotify', {
            email: myEmail,
            id: props.id
        });
    }


    return(
        <div className="NotifyModal" style={{opacity: opacity, height: height, marginBottom: marginBot, padding: padding}}>
            <div className="notify_modal_header">
                <img className="notify_modal_header_inbox" src={(props.type === 'activity') ? watchIcon : inboxIcon} alt="inbox"></img>
                <img className="notify_modal_header_close" src={closeIcon} alt="close" onClick={() => deleteNotify()}></img>
            </div>
            <div className="notify_modal_body">
                <div className="notify_modal_body_title">{title}</div>
                <div className="notify_modal_body_desc">{desc}</div>
            </div>
        </div>
    );
}

export default NotifyModal;