import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import DownloadDir from './pages/DownloadDir/DownloadDir';

const Routing = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/download_dir" component={DownloadDir}></Route>
                <Route path="/" component={App}></Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Routing;