import './WindowBtn.scss';

import PropTypes from 'prop-types';

const WindowBtn2 = props => {
    return(
        <button className="WindowBtn" onClick={() => props.handleClick(props.text)} style={{background: props.background}}>
            <p className="window_btn_text">{props.text}</p>
        </button>
    );
}

WindowBtn2.propTypes = {
    text: PropTypes.string.isRequired
}

export default WindowBtn2;