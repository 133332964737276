const dropDownReducer = (state = null, action) => {
    switch(action.type)
    {
        case 'changeDropDownOpen':
            state = action.id;
            return state;
        default :
            return state;
    }
}

export default dropDownReducer;