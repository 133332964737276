import './SelectFilter.scss';
import {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {filterItemAction} from '../../redux/actions/filterItem';

import caretDown from '../../img/caret_down.svg';

const SelectFilter = () => {
    const [listHeight, setListHeigth] = useState('0px');
    const [iconTransform, setIconTransform] = useState('rotate(0deg)');

    const filter = useSelector(state => state.filterItem);
    const dispatch = useDispatch();

    const changeFilter = name => {
        dispatch(filterItemAction(name));
        changeListDisp();
    }

    const changeListDisp = () => {
        if(listHeight === '0px') {
            setListHeigth('114px');
            setIconTransform('rotate(180deg)');
        } else {
            setListHeigth('0px');
            setIconTransform('rotate(0deg)');
        }
    }

    return(
        <div className="SelectFilter">
            <div className="select_filter_text" onClick={() => changeListDisp()}>
                Ordina per:
                <div className="select_filter_textfilter">&nbsp;{filter}</div>
            </div>

            <div className="select_filter_rigth" onClick={() => changeListDisp()}>
                <img className="select_filter_right_caret" src={caretDown} style={{transform: iconTransform}}></img>
            </div>

            <div className="select_filter_list" style={{height: listHeight}}>
                <div className="select_filter_list_text" onClick={() => changeFilter('Tipo')}>Tipo</div>
                <div className="select_filter_list_text" onClick={() => changeFilter('Nome')}>Nome</div>
                <div className="select_filter_list_text" onClick={() => changeFilter('Data Crescente')}>Data Crescente</div>
                <div className="select_filter_list_text" onClick={() => changeFilter('Data Decrescente')}>Data Decrescente</div>
            </div>
        </div>
    );
}

export default SelectFilter;