import './CalendarPageChanger.scss';

import caretDx from '../../../img/caret_dx.svg';
import caretSx from '../../../img/caret_sx.svg';
import { useDispatch, useSelector } from 'react-redux';
import { calendarAction} from '../../../redux/actions/calendar';

const CalendarPageChanger = () => {
    const calendar = useSelector(state => state.calendar);
    const dispatch = useDispatch();

    const changePage = sxOrDx => {
        if(calendar) {
            const childNum = document.getElementById('calendarPageHeader').childElementCount;

            if(sxOrDx === 'sx') {
                dispatch(calendarAction(calendar.day - childNum, calendar.month, calendar.year, calendar.filter));
            } else {
                dispatch(calendarAction(calendar.day + childNum, calendar.month, calendar.year, calendar.filter));
            }
        }
    }

    return(
        <div className="CalendarPageChanger">
            <div className="calendar_page_changer_btn" onClick={() => changePage('sx')}>
                <img className="calendar_page_changer_btn_icon" src={caretSx}></img>
            </div>

            <div className="calendar_page_changer_btn" onClick={() => changePage('dx')}>
                <img className="calendar_page_changer_btn_icon" src={caretDx}></img>
            </div>
        </div>
    );
}

export default CalendarPageChanger;