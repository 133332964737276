import './Modal.scss';
import PropTypes from 'prop-types';

import closeIcon from '../../img/close.svg';

const TextModal = props => {
    return (
        <div className="Modal" style={{display: props.display}} onClick={e => e.stopPropagation()}>
            <div className="modal_container">
                <div className="modal_container_header">
                    <img className="modal_container_header_close" src={closeIcon} alt="close" onClick={() => props.handleClose()}></img>
                </div>
                <p className="modal_container_text">{props.text}</p>
                <div className="modal_container_header"></div>
            </div>
        </div>
    );
}

TextModal.propTypes = {
    text: PropTypes.string.isRequired,
    display: PropTypes.oneOf(['none', 'block']).isRequired
}

export default TextModal;