export const dragAndDropAction = (folderId, isDrag, folderPos, itemId) => {
    return{
        type: 'changeState',
        drag: {
            folderId: folderId,
            isDrag: isDrag,
            folderPos: folderPos,
            itemId: itemId
        } 
    }
}