import './WindowItem.scss';

import icon from '../../img/Folder.svg';
import opt from '../../img/pall.svg';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {currentFolderAction} from '../../redux/actions/currentFolder';
import RidenominaModal from '../Modal/RidenominaModal';
import DeleteModal from '../Modal/DeleteModal';
import { deleteFolder, getFolder, renameFolder } from '../../requests/folder';
import {dragAndDropAction} from '../../redux/actions/dragAndDrop';
import {dropDownAction} from '../../redux/actions/dropDown';
import { serverHost } from '../../utils/ServerHost';

const FolderItem = props => {
    const [folder, setFolder] = useState({files : [], contacts: []});
    const [disp, setDisp] = useState('none');
    const [clickedOPT, setClickedOpt] = useState(false);
    const [showList, setShowList] = useState({width: "0px", height: "0px", fontSize: "0px"});
    const [renameDisp, setRenameDisp] = useState('none');
    const [deleteDisp, setDeleteDisp] = useState('none');

    const dispatch = useDispatch();
    const drag = useSelector(state => state.dragAndDrop);
    const dropDown = useSelector(state => state.dropDown);
    const currentFolder = useSelector(state => state.currentFolder);

    useEffect(() => {
        setDisp('none');
    }, [currentFolder]);

    useEffect(() => {
        if(dropDown === props.id) {
            setClickedOpt(true);
        } else {
            setClickedOpt(false);
        }
    }, [dropDown]);

    useEffect(() => {
        getFolder(props.id).then(res => {
            setFolder(res);
            if(res.parentId === currentFolder._id) {
                setDisp('block');
            }
        });
    }, [props.id]);

    const changeFolder = e => {
        if(!e.target.id.includes('windowItemOpt'))
        {
            dispatch(currentFolderAction('cambiaCartella', folder));
        }
    }

    useEffect(() => {
        if(clickedOPT) {
            setShowList({width: "170px", fontSize: "16px"});
        }
        else {
            setShowList({width: "0px", fontSize: "0px"});
        }
    }, [clickedOPT]);

    const renameClick = () => {
        dispatch(dropDownAction(null));
        setRenameDisp('block');
    }

    const rename = newName => {
        renameFolder(newName, folder._id, folder.parentId).then(json => {
            if(json.resp) {
                const temp = {...folder};
                temp.name = newName
                setFolder(temp);
            }
        });
    }

    const deleteClick = () => {
        dispatch(dropDownAction(null));
        setDeleteDisp('block');
    }

    const optClick = e => {
        if(dropDown === props.id) {
            dispatch(dropDownAction(null));
        } else {
            dispatch(dropDownAction(props.id));
        }
        e.stopPropagation();
    }

    const del = () => {
        setDeleteDisp('none');
        deleteFolder(folder._id).then(res => {
            getFolder(folder.parentId).then(json => {
                dispatch(currentFolderAction('cambiaCartella', {}));
                dispatch(currentFolderAction('cambiaCartella', json));
            });
        });
    }

    const drop = e => {
        if(drag.isDrag === true) {
            const rect = e.target.getBoundingClientRect();
            dispatch(dragAndDropAction(props.id, false, {top: rect.top, left: rect.left}, drag.itemId));
        }
    }

    const downloadAllFolder = () => {
        window.open('/download_dir/' + folder._id);
    }

    return(
        <div className="WindowItem" onClick={e => changeFolder(e)} style={{display: disp}} onMouseUp={e => drop(e)}>
            <div className="window_item_svg">
                <img className="window_item_svg_icon" src={icon} alt="item_icon"></img>
                <div className="window_item_row">
                    <p className="window_item_row_text">{folder.name}</p>
                </div>

                <div className="window_item_row">
                <p className="window_item_row_text2">{folder.files.length + folder.contacts.length} items · {Math.trunc(folder.size)} Mb</p>
                </div>
            </div>

            <img className="window_item_opt" id="windowItemOpt" src={opt} alt="options" onClick={e => optClick(e)}></img>
            <div className="window_item_opt_list" id="windowItemOptList" style={showList}>
                <p className="window_item_opt_list_btn" id="windowItemOptListBtn1" onClick={() => deleteClick()}>Elimina</p>
                <p className="window_item_opt_list_btn" id="windowItemOptListBtn2" onClick={() => renameClick()}>Rinomina</p>
                <p className="window_item_opt_list_btn" id="windowItemOptListBtn3" onClick={() => downloadAllFolder()}>Scarica cartella</p>
            </div>

            <RidenominaModal handleClose={() => setRenameDisp('none')} display={renameDisp} placeholder={folder.name} handle={rename} id={folder.name}/>
            <DeleteModal handleClose={() => setDeleteDisp('none')} display={deleteDisp} handle={del}/>
        </div>  
    );
}

export default FolderItem;