export const calendarAction = (day, month, year, filter) => {
    return{
        type: 'changeCalendar',
        obj: {
            day: day,
            month: month,
            year: year,
            filter: filter
        }
    }
}