import './Modal.scss';
import PropTypes from 'prop-types';

import closeIcon from '../../img/close.svg';
import WindowBtn2 from '../WindowBtn/WindowBtn2';

const RidenominaModal = props => {
    const rename = e => {
        if(e.code === 'Enter') {
            rinomina();
        }
    }

    const rinomina = () => {
        const input = document.getElementById('mInput' + props.id);
        props.handle(input.value);
        input.value = "";
        props.handleClose();
    }

    return(
        <div className="Modal" style={{display: props.display}} onClick={e => e.stopPropagation()}>
            <div className="modal_container">
                <div className="modal_container_header">
                    <p className="modal_container_header_title">Rinomina</p>
                    <img className="modal_container_header_close" src={closeIcon} alt="close" onClick={() => props.handleClose()}></img>
                </div>

                <input className="modal_input" id={"mInput" + props.id} placeholder={props.placeholder} onKeyDown={e => rename(e)}></input>

                <div className="modal_container_header">
                    <div className="modal_container_header_bottom_btn">
                        <WindowBtn2 text="Conferma" handleClick={rinomina}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

RidenominaModal.propTypes = {
    display: PropTypes.oneOf(['none', 'block']).isRequired
}

export default RidenominaModal;