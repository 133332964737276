import './Modal2.scss';

import activityIcon from '../../img/orologio.svg';
import closeIcon from '../../img/closeBlue.svg';
import WindowBtn2 from '../WindowBtn/WindowBtn2';
import { useEffect, useState } from 'react';
import SelectBox from '../SelectBox/SelectBox';

import folderIcon from '../../img/Folder.svg';
import contactIcon from '../../img/Contact.svg';
import fileIcon from '../../img/File.svg';
import { serverHost } from '../../utils/ServerHost';
import { useDispatch, useSelector } from 'react-redux';
import ColorsCircleButton from '../ColorsCircleButton/ColorsCircleButton';
import { calendarAction } from '../../redux/actions/calendar';

import { saveActivity } from '../../requests/activity';

import React from 'react';
import { colors } from '../../utils/utils';
import ClickOutside from 'react-click-outside';



const TaskModal = props => {
    const [errorDisp, setErrorDisp] = useState('none');
    const [allItems, setAllItems] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const [desc, setDesc] = useState(null);
    const [refFile, setRefFile] = useState(null);
    const [refUser, setRefUser] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [note, setNote] = useState(null);
    const [color, setColor] = useState('#50b5ff');
    const [display, setDisplay] = useState('none');
    const [modalAnim, setModalAnim] = useState();
    const [animation, setAnimation] = useState();


    const currentFolder = useSelector(state => state.currentFolder);
    const calendar = useSelector(state => state.calendar);
    const dispatch = useDispatch();

    useEffect(() => {
        riempiArr();
    }, [currentFolder]);

    const riempiArr = () => {
        fetch(serverHost + '/all_items', { credentials: 'include' }).then(res => res.json()).then(json => {
            setAllItems(json.map(item => {
                item.icon = selectIcon(item);
                return item;
            }));
        });

        fetch(serverHost + '/user/', { credentials: 'include' }).then(res => res.json()).then(json => {
            setAllUser(json.map(item => {
                item.icon = selectIcon(item);
                return item;
            }));
        });
    }

    const keyDown = e => {
        if (e.code === 'Enter') {
            createActivity();
        }
    }

    const selectIcon = item => {
        switch (item.type) {
            case 'file':
                return fileIcon;
            case 'folder':
                return folderIcon;
            case 'contact':
                return contactIcon;
            default:
                return null;
        }
    }

    const resetInputs = () => {
        const ids = ['desc', 'startDate', 'endDate', 'note'];
        ids.forEach(id => document.getElementById(id + props.id).value = "");

        setRefUser(null);
        setRefFile(null);
        setColor('#50b5ff');
        setAllUser(null);
        setAllItems(null);
        riempiArr();
    }

    const createActivity = () => {
        if (desc && refFile && refUser && startDate && endDate) {
            saveActivity(desc, refFile, refUser, startDate, endDate, note, color).then(res => {
                props.handleClose();
                resetInputs();
                dispatch(calendarAction(calendar.day - 1, calendar.month, calendar.year, calendar.filter));
                dispatch(calendarAction(calendar.day, calendar.month, calendar.year, calendar.filter));
            });
        }
        else {
            setErrorDisp('block');
        }
    }

    useEffect(() => {
        if (props.display === 'none') {
            setAnimation('hideBG .25s ease-in forwards');
            setModalAnim('hideModal .25s ease-in forwards');
            setTimeout(() => setDisplay('none'), 300);
        } else {
            setDisplay(props.display);
            setAnimation('showBG .25s ease-in forwards');
            setModalAnim('showModal .25s ease-in forwards');
        }
    }, [props.display]);

    return (
        <div className="Modal2" onClick={e => e.stopPropagation()} onKeyDown={e => keyDown(e)} style={{ display: display, animation: animation }}>
            <ClickOutside onClickOutside={() => props.handleClose()}>


                <div className="modal2_container" style={{ animation: modalAnim }}>
                    <div className="modal2_container_header">
                        <img src={activityIcon} alt="contact" height="20px"></img>
                        <div className="modal2_container_header_text">Aggiungi attività</div>
                        <img className="modal2_close_icon" src={closeIcon} alt="close" height="28px" onClick={() => props.handleClose()}></img>
                    </div>

                    <div className="modal2_container_body">
                        <div className="modal2_task_text">Descrizione attività</div>
                        <input className="modal2_contact_input_text modal2_task_input_text" onChange={(e) => setDesc(e.target.value)} placeholder="Descrizione attività" id={'desc' + props.id}></input>

                        <div className="modal2_task_text">File di riferimento</div>
                        <SelectBox text="Scegli il file dalla lista" handleValue={setRefFile} items={allItems} />

                        <div className="modal2_task_text">Assegnazione</div>
                        <SelectBox text="Seleziona la persona" handleValue={setRefUser} items={allUser} />

                        <div className="modal2_row">
                            <div className="modal2_col">
                                <div className="modal2_task_text">Data inizio</div>
                                <input className="modal2_contact_input_text modal2_task_input_text" type="date" onChange={(e) => setStartDate(new Date(e.target.value))} id={'startDate' + props.id} required ></input>
                            </div>
                            <div className="modal2_col">
                                <div className="modal2_task_text">Data fine</div>
                                <input className="modal2_contact_input_text modal2_task_input_text" type="date" onChange={(e) => setEndDate(new Date(e.target.value))} id={'endDate' + props.id} required ></input>
                            </div>
                        </div>

                        <div className="modal2_task_text" >Note</div>
                        <textarea className="modal2_contact_textarea" placeholder="Note" onChange={e => setNote(e.target.value)} id={'note' + props.id}></textarea>

                        <div className="modal2_task_text">Scegli un colore da assegnare</div>
                        <div className="modal2_task_color_container">
                            {
                                colors.map(el => <ColorsCircleButton color={el} key={el} selectedColor={color} handleClick={setColor} />)
                            }
                        </div>

                        <div className="modal2_contact_error" style={{ display: errorDisp }}>Errore, compila tutti i campi!</div>
                        <div className="modal2_contact_footer">
                            <WindowBtn2 text="Crea attività" handleClick={createActivity} />
                        </div>
                    </div>
                </div>
            </ClickOutside>

        </div>
    );
}

export default TaskModal;