const calendarReducer = (state = null, action) => {
    switch(action.type)
    {
        case 'changeCalendar':
            state = action.obj;
            return state;
        default :
            return state;
    }
}

export default calendarReducer;