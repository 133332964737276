const openSideBarReducer = (state = false, action) => {
    switch(action.type)
    {
        case 'open':
            state = action.bool;
            return state;
        default :
            return state;
    }
}

export default openSideBarReducer;