import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { changeDate, patchDate } from '../../../requests/activity';
import { getContact } from '../../../requests/contact';
import { getFile } from '../../../requests/file';
import { getFolder } from '../../../requests/folder';
import { getInitials } from '../../../requests/user';
import { colors, getRandomInt, removePxFromString } from '../../../utils/utils';
import ViewTaskModal from '../../Modal/ViewTaskModal';
import './CalendarTask.scss';

const CalendarTask = props => {
    const [width, setWidth] = useState(0);
    const [left, setLeft] = useState(0);
    const [fileName, setFileName] = useState('');
    const [elementLeft, setElementLeft] = useState(0);
    const [initials, setInitials] = useState('');
    const [displayModal, setDisplayModal] = useState('none');
    const [color, setColor] = useState(colors[getRandomInt(0, 5)]);
    const [visibleWidth, setVisibleWidth] = useState(0);
    const [updateData, setUpdateData] = useState(false);

    const calendar = useSelector(state => state.calendar);

    useEffect(() => setElementLeft(document.getElementById('task_' + props.id).getBoundingClientRect().left), [left]);

    useEffect(() => {
        let width;
        const task = props.task;
        const calendarStartDate = new Date(calendar.year, calendar.month, calendar.day).getTime();
        const startDateMS = new Date(task.startDate).getTime();
        const endDateMS = new Date(task.endDate).getTime();

        const taskDay = (((endDateMS - startDateMS) / 1000) / 84600) + 1;
        const fromCalToTaskDay = (((startDateMS - calendarStartDate) / 1000) / 84600);

        const leftTot = Math.trunc(fromCalToTaskDay + 0.000001) * props.width;
        width = Math.round(taskDay) * props.width;
        
        setWidth(width - 10);
        setLeft(leftTot);
    }, [props.width, props.task]);

    useEffect(() => {
        const id = props.task.refFile;
        setItemName(id);
        getInitials(props.task.refUser).then(res => setInitials(res));
    }, [props.task]);

    //controllo sul file o cartella o contatto
    const setItemName = id => {
        getFile(id).then(file => {
            if(file) {
                setFileName(file.name);
            } else {
                getFolder(id).then(folder => {
                    if(folder) {
                        setFileName(folder.name);
                    } else {
                        getContact(id).then(contact => {
                            if(contact) {
                                setFileName(contact.referente);
                            } else {
                                setFileName('Non è stato possibile recuperare il file');
                            }
                        });
                    }
                });
            }
        });
    }

    const resize = e => {
        e.stopPropagation();
        if(!updateData) {
            document.body.style.cursor = "e-resize";
            document.addEventListener('mouseup', relaseItem, {once: true});
            document.addEventListener('mousemove', followMouse);
        }
    }

    const followMouse = e => {
        setWidth(e.clientX - elementLeft);
    }

    const relaseItem = () => {
        setUpdateData(true);
        document.removeEventListener('mousemove', followMouse);
        document.removeEventListener('mouseup', relaseItem);
        const taskWidth = document.getElementById('task_' + props.id).offsetWidth;
        const newWidth = (Math.trunc(taskWidth / props.width) + 1) * props.width;
        setWidth(newWidth - 10);
        const giorni = ((newWidth + left)/props.width) - 1;
        patchDate(props.id, new Date(calendar.year, calendar.month, calendar.day + giorni)).then(res => {
            setUpdateData(false);
        });
    }

    const drag = e => {
        e.stopPropagation();
        if(!updateData) {
            const initMouse = e.clientX;

            const followMouse2 = (e) => {
                setLeft(left + (e.clientX - initMouse));
            }

            const relaseItem2 = e => {
                setUpdateData(true);
                const newLeft = removePxFromString(document.getElementById('task_' + props.id).style.left);
                document.removeEventListener('mousemove', followMouse2);
                document.removeEventListener('mouseup', relaseItem2);
                const taskLeft = Math.trunc(newLeft / props.width) * props.width;
                setLeft(taskLeft);
                const giorni = (taskLeft - left) / props.width;
                if(giorni !== 0) {
                    changeDate(props.id, giorni).then(res => {
                        setUpdateData(false);
                    });
                } else {
                    setUpdateData(false);
                }
            }

            document.addEventListener('mouseup', relaseItem2, {once: true});
            document.addEventListener('mousemove', followMouse2);
        }
    }

    useEffect(() => {
        if(left < 0) setVisibleWidth(width + left);
        else setVisibleWidth(width);
    }, [left, width]);

    useEffect(() => {
        const task = document.getElementById('task_' + props.id);
        const resizeBtn = document.getElementById(props.id);
        if(updateData) {
            task.style.cursor = "wait";
            resizeBtn.style.cursor = "wait";
        } else {
            task.style.cursor = "pointer";
            resizeBtn.style.cursor = "e-resize";
        }
    }, [updateData]);

    return(
        <div className="CalendarTask" style={{width: width, left: left}} id={'task_' + props.id} onDoubleClick={() => setDisplayModal('block')} onMouseDown={e => drag(e)}>

            <div className="calendar_task_desc" style={{left: (left < 0)? left * -1 : 0}}>
                <div className="calendar_task_leftline" style={{background: props.task.color}}></div>
                <div className="calendar_task_desk2" style={{width: (left >= 0)? '50%' : visibleWidth / 2}}>
                    <div className="calendar_task_desc_title">{props.task.descrizione}</div>
                    <div className="calendar_task_desk_filename">{fileName}</div>
                </div>
            </div>

            <div className="calendar_task_resize" onMouseDown={e => resize(e)} id={props.id} onClick={e => e.stopPropagation()}></div>
            <button className="calendar_task_initials" style={{background: color}}>{initials}</button>
            <ViewTaskModal display={displayModal} handleClose={() => setDisplayModal('none')} id={props.id}/>
        </div>
    );
}

export default CalendarTask;