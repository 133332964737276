import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartellaModal from '../../components/Modal/CartellaModal';
import TextModal from '../../components/Modal/TextModal';
import {currentFolderAction} from '../../redux/actions/currentFolder';
import '../Window.scss';

import caret from '../../img/caret.svg';
import { saveFile } from '../../requests/file';
import { getFolder } from '../../requests/folder';
import ContactModal from '../../components/Modal/ContactModal';
import ContactItem from '../../components/WindowItem/ContactItem';
import { getAllContact } from '../../requests/contact';

const Contact = props => {
    const [textModalDisp, setTextModalDisp] = useState('none');
    const [folderModalDisp, setFolderModalDisp] = useState('none');
    const [contactModalDisp, setContactModalDisp] = useState('none');
    const [dispPath, setDispPath] = useState('none');
    const [warnDisp, setWarnDisp] = useState('none');

    const [contacts, setContacts] = useState([]);

    const [parentFolder, setParentFolder] = useState('');
    const currentFolder = useSelector(state => state.currentFolder);
    const search = useSelector(state => state.searched);
    const dispatch = useDispatch();

    useEffect(() => {
        if(contacts.length > 0)  {
            setWarnDisp('none');
        }
        else {
            setWarnDisp('block');
        }
    }, [ contacts]);


    useEffect(() => {
        getAllContact().then(contacts => {
            const contactsId = contacts.map(contact => contact._id);
            setContacts(contactsId);
        })
    }, [props.style]);

    const closeTextModal = () => {
        setTextModalDisp('none');
    }

    const closeFolderModal = () => {
        setFolderModalDisp('none');
    }

    const btnClick = text => {
        if(text === 'Cartella') {
            setFolderModalDisp('block');
        }
        else if(text === 'File') {
            document.getElementById('load_file').click();
        }
        else if(text === 'Contatto') {
            setContactModalDisp('block');
        }
    }

    const loadFile = e => {
        if(e.target.value)
        {
            setTextModalDisp('block');

            saveFile(e.target.files[0], currentFolder._id)
            .then(res => {
                getFolder(currentFolder._id).then(res=>{
                    dispatch(currentFolderAction('cambiaCartella', {}));
                    dispatch(currentFolderAction('cambiaCartella', res));
                    setTextModalDisp('none');
                });
            });
        }
    }

    return(
        <div className="Window" style={props.style}>
            <div className="window_header">
                <div className="window_header_sx">
                    <p className="window_header_title">Contatti</p>
                </div>
            </div>

            <div className="window_path">
                <p className="window_path_parent window_path_text" onClick={() => dispatch(currentFolderAction('cambiaCartella', parentFolder))} style={{display: dispPath}}>{parentFolder.name}</p>
                <img src={caret} alt="caret" width="6%" style={{display: dispPath}}></img>
            </div>

            <div className="window_item">
                {
                    (contacts)? contacts.map(contact => <ContactItem key={contact} id={contact} search={search} isDraggable={false}/>) : <div></div>
                }
                <p className="window_item_warning" style={{display: warnDisp}}>Nessun Contatto</p>
            </div>
            
            <input type="file" id="load_file" style={{display: 'none'}} onChange={e => loadFile(e)}></input>
            <TextModal text="Loading..." display={textModalDisp} handleClose={closeTextModal}/>
            <CartellaModal display={folderModalDisp} handleClose={closeFolderModal}/>
            <ContactModal display={contactModalDisp} handleClose={() => setContactModalDisp('none')}/>
        </div>
    );
}

export default Contact;