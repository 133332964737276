import { useEffect, useState } from 'react';
import './SelectBox.scss';

import caret from '../../img/caret_down.svg';
import SelectBoxItem from '../SelectBoxItem/SelectBoxItem';

const SelectBox = props => {
    const [text, setText] = useState(props.text);
    const [isOpen, setIsOpen] = useState(false);
    const [iconTransform, setIconTransform] = useState('rotate(0deg)');
    const [listDisp, setListDisp] = useState('none');
    const [items, setItems] = useState(props.items);

    useEffect(() => {
        if(props.set) {
            itemClick(props.set.name, props.set.id);
        }
    }, [props.set]);

    useEffect(() => {
        setItems(props.items);

        if(!props.items) {
            setText(props.text);
        }
    }, [props.items]);

    useEffect(() => {
        if(isOpen) {
            setIconTransform('rotate(180deg)');
            setListDisp('flex');
        } else {
            setIconTransform('rotate(0deg)');
            setListDisp('none');
        }
    }, [isOpen]);

    const itemClick = (name, id) => {
        props.handleValue(id);
        setText(name);
        setIsOpen(false);
    }

    const searchInList = e => {
        const value = e.target.value.toUpperCase();

        setItems(props.items.filter(item => item.nome.toUpperCase().includes(value)));
    }

    return(
        <div className="SelectBox">
            <div className="select_box_text" style={{opacity: (text === props.text)? '0.4' : '1'}} onClick={() => setIsOpen(!isOpen)}>{text}</div>

            <div className="select_box_dx_caret" onClick={() => setIsOpen(!isOpen)}>
                <img className="select_box_dx_caret_img" src={caret} width="70%" alt="caret" style={{transform: iconTransform}}></img>
            </div>

            <div className="select_box_list" style={{display: listDisp}}>
                <div className="select_box_list_header">
                    <input className="select_box_list_header_input" placeholder="Cerca..." onChange={(e) => searchInList(e)}></input>
                </div>
                
                <div className="select_box_list_body">
                    {
                        (items)? items.map(item => <SelectBoxItem key={item.id} item={item} handleClick={itemClick}/>) : <div></div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SelectBox;