import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Login from "./components/Auth/Login";
import Header from "./components/Header/Header";
import SideBar from "./components/SideBar/SideBar";
import { currentFolderAction } from "./redux/actions/currentFolder";
import { isLoggedAction } from "./redux/actions/isLogged";
import { getFolder } from "./requests/folder";
import socketIOClient from "socket.io-client";
import { socketAction } from "./redux/actions/socket";

import { serverHost } from "./utils/ServerHost";
import PageChanger from "./components/PageChanger/PageChanger";
import { dropDownAction } from "./redux/actions/dropDown";

function App() {
  const dispatch = useDispatch();

  const isLogged = useSelector((state) => state.isLogged);

  useEffect(() => {
    fetch(serverHost + "/is_logged", {
      credentials: "include",
    })
      .then((res) => {
        if (res.status !== 401 && res.status >= 200 && res.status < 300) {
          return res.text();
        }
        return false;
      })
      .then((email) => {
        dispatch(isLoggedAction("newBool", email));
      });

    window.addEventListener("click", () => {
      dispatch(dropDownAction(null));
    });
  }, []);

  useEffect(() => {
    if (isLogged) {
      getFolder("root").then((res) =>
        dispatch(currentFolderAction("cambiaCartella", res))
      );
      dispatch(socketAction("changeRef", socketIOClient(serverHost)));
    }
  }, [isLogged]);

  if (!isLogged) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header />
        <div className="app_remaining_page">
          <SideBar />
          <PageChanger />
        </div>
      </div>
    );
  }
}

export default App;
