import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import  './Calendar.scss';
import CalendarHeader from './CalendarHeader';
import CalendarPage from './CalendarPage';
import {calendarAction} from '../../redux/actions/calendar';

const Calendar = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const dateNow = new Date();
        dispatch(calendarAction(dateNow.getDate(), dateNow.getMonth(), dateNow.getFullYear(), 'week'));
    }, []);

    return(
        <div className="Calendar">
            <CalendarHeader />

            <div className="calendar_body">
                <div className="calendar_body_title">
                    <div className="calendar_body_title_text">Attività</div>
                </div>

                <CalendarPage />
            </div>
        </div>
    );
}

export default Calendar;