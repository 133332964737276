import './SearchBar.scss';

import search from '../../img/Vector.svg';
import { useDispatch, useSelector } from 'react-redux';
import {searchedAction} from '../../redux/actions/searched';
import { useEffect } from 'react';

const SearchBar = () => {
    const dispatch = useDispatch();
    const currentFolder = useSelector(state => state.currentFolder);

    useEffect(() => {
        document.getElementById('searchBar_123456789').value = "";
    }, [currentFolder]);

    return(
        <div className="SearchBar">
            <img className="searchbar_icon" src={search} alt="icon"></img>
            <input className="searchbar_input" id="searchBar_123456789" placeholder="Cerca" onChange={e => dispatch(searchedAction('cambiaStringa', e.target.value))}></input>
        </div>
    );
}

export default SearchBar;