import './Modal2.scss';

import contactIcon from '../../img/Contact.svg';
import closeIcon from '../../img/closeBlue.svg';
import WindowBtn2 from '../WindowBtn/WindowBtn2';
import React, { useEffect, useState } from 'react';
import {saveContact} from '../../requests/contact';
import ClickOutside from 'react-click-outside'
import { useDispatch, useSelector } from 'react-redux';
import { getFolder } from '../../requests/folder';
import { currentFolderAction } from '../../redux/actions/currentFolder';

const ContactModal = props => {
    const [errorDisp, setErrorDisp] = useState('none');
    const [display, setDisplay] = useState('none');
    const [modalAnim, setModalAnim] = useState();
    const [animation, setAnimation] = useState();

    const currentFolder = useSelector(state => state.currentFolder);
    const dispatch = useDispatch();

    const keyDown = e => {
        if(e.code === 'Enter') {
            createContact();
        }
    }

    const createContact = () => {
        const agencyName = document.getElementById('modal2AgencyName');
        const referente = document.getElementById('modal2Referente');
        const numero = document.getElementById('modal2contactNumber');
        const email = document.getElementById('modal2contactEmail');
        const note = document.getElementById('modal2contactNote');

        if(!agencyName.value || !referente.value || !numero.value || !email.value) {
            setErrorDisp('block');
        } 
        else {
            saveContact(agencyName.value, referente.value, numero.value, email.value, note.value, currentFolder._id)
            .then(res => {
                getFolder(currentFolder._id).then(reload => {
                    dispatch(currentFolderAction('cambiaCartella', {}));
                    dispatch(currentFolderAction('cambiaCartella', reload));
                    setErrorDisp('none');
                    agencyName.value = "";
                    referente.value = "";
                    numero.value = "";
                    email.value = "";
                    note.value = "";
                    props.handleClose();
                });
            });
        }
    }

    useEffect(() => {
        if(props.display === 'none') {
            setAnimation('hideBG .25s ease-in forwards');
            setModalAnim('hideModal .25s ease-in forwards');
            setTimeout(() => setDisplay('none'), 300);
        } else {
            setDisplay(props.display);
            setAnimation('showBG .25s ease-in forwards');
            setModalAnim('showModal .25s ease-in forwards');
        }
    }, [props.display]);

    return(
        <div className="Modal2" onClick={e => e.stopPropagation()} onKeyDown={e => keyDown(e)} style={{display: display, animation: animation}}>
         <ClickOutside onClickOutside={() => props.handleClose()}>
            <div  className="modal2_container" style={{animation: modalAnim}}>
                <div className="modal2_container_header">
                    <div className="modal2_col">
                        <img src={contactIcon} alt="contact" height="100%"></img>
                    </div>
                    <img className="modal2_close_icon" src={closeIcon} alt="close" height="28px" onClick={() => props.handleClose()}></img>
                </div>

                <div className="modal2_container_body">
                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Nome azienda</div>
                            <input className="modal2_contact_input_text" id={"modal2AgencyName"} placeholder="Nome azienda"></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Referente</div>
                            <input className="modal2_contact_input_text" id={"modal2Referente"} placeholder="Referente"></input>
                        </div>
                    </div>

                    <div className="modal2_row">
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Numero</div>
                            <input className="modal2_contact_input_text" id={"modal2contactNumber"} placeholder="Numero"></input>
                        </div>
                        <div className="modal2_col">
                            <div className="modal2_contact_text">Indirizzo e-mail</div>
                            <input className="modal2_contact_input_text" id={"modal2contactEmail"} placeholder="Indirizzo e-mail"></input>
                        </div>
                    </div>
                    <div className="modal2_contact_text">Note</div>
                    <textarea className="modal2_contact_textarea" id={"modal2contactNote"} placeholder="Note"></textarea>
                    <div className="modal2_contact_error" style={{display: errorDisp}}>Errore, compila tutti i campi!</div>
                    <div className="modal2_contact_footer">
                        <WindowBtn2 text="Crea contatto" handleClick={createContact}/>
                    </div>
                </div>
            </div>
            </ClickOutside>
        </div>
    );
}

export default ContactModal;