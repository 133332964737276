import './Modal.scss';
import PropTypes from 'prop-types';

import closeIcon from '../../img/close.svg';
import WindowBtn2 from '../WindowBtn/WindowBtn2';

const DeleteModal = props => {
    return (
        <div className="Modal" style={{display: props.display}} onClick={e => e.stopPropagation()}>
            <div className="modal_container">
                <div className="modal_container_header">
                    <p className="modal_container_header_title">Conferma eliminazione</p>
                    <img className="modal_container_header_close" src={closeIcon} alt="close" onClick={() => props.handleClose()}></img>
                </div>
                <p className="modal_container_text">Sei sicuro di voler rimuovere l'elemento?</p>
                <div className="modal_container_header">
                    <div className="modal_container_header_bottom_btn">
                        <WindowBtn2 text="Annulla" background="#8c8c8c" handleClick={props.handleClose}/>
                        <div className="modal_container_header_bottom_btn_space"></div>
                        <WindowBtn2 text="Conferma" background="#d03232" handleClick={props.handle}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

DeleteModal.propTypes = {
    display: PropTypes.oneOf(['none', 'block']).isRequired
}

export default DeleteModal;