import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartellaModal from "../../components/Modal/CartellaModal";
import TextModal from "../../components/Modal/TextModal";
import SearchBar from "../../components/SearchBar/SearchBar";
import WindowBtn from "../../components/WindowBtn/WindowBtn";
import FolderItem from "../../components/WindowItem/FolderItem";
import FileItem from "../../components/WindowItem/FileItem";
import { currentFolderAction } from "../../redux/actions/currentFolder";
import "../Window.scss";

import caret from "../../img/caret.svg";
import { getFile, saveFile } from "../../requests/file";
import { getFolder } from "../../requests/folder";
import ContactModal from "../../components/Modal/ContactModal";
import ContactItem from "../../components/WindowItem/ContactItem";
import { dragAndDropAction } from "../../redux/actions/dragAndDrop";
import { addInArray } from "../../utils/utils";
import shapeIcon from "../../img/Shape.svg";
import SelectFilter from "../../components/SelectFilter/SelectFilter";
import { getContact } from "../../requests/contact";
//import PdfShower from "../../components/PdfShower/PdfShower";
import ImageShower from "../../components/ImageShower/ImageShower";

const Drive = (props) => {
  const [textModalDisp, setTextModalDisp] = useState("none");
  const [folderModalDisp, setFolderModalDisp] = useState("none");
  const [contactModalDisp, setContactModalDisp] = useState("none");
  const [dispPath, setDispPath] = useState("none");
  const [warnDisp, setWarnDisp] = useState("none");
  const [allItems, setAllItems] = useState([]);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const drag = useSelector((state) => state.dragAndDrop);
  const [parentFolder, setParentFolder] = useState("");
  const currentFolder = useSelector((state) => state.currentFolder);
  const search = useSelector((state) => state.searched);
  const [newFile, setNewFile] = useState();
  const dispatch = useDispatch();
  const backRef = useRef();

  const filter = useSelector((state) => state.filterItem);

  useEffect(() => {
    if (allItems.length > 0) {
      const arrCopy = [...allItems];
      arrCopy.push(newFile);
      setAllItems(arrCopy);
      //  document.getElementById("app-loader").style.display = "none";
    } else {
      setAllItems([newFile]);
    }
  }, [newFile]);

  useEffect(() => {
    setAllItems([]);
    const allItem = [];
    //   document.getElementById("app-loader").style.display = "block";
    getAllFolder(allItem).then((res) => {
      getAllFile(allItem).then((res) => {
        getAllContact(allItem).then((res) => {
          switch (filter) {
            case "Nome":
              setAllItems(allItem.sort((a, b) => a.name.localeCompare(b.name)));
              //   document.getElementById("app-loader").style.display = "none";
              break;
            case "Data Crescente":
              setAllItems(
                allItem.sort(
                  (a, b) =>
                    new Date(a.timestamp).getTime() -
                    new Date(b.timestamp).getTime()
                )
              );
              //       document.getElementById("app-loader").style.display = "none";
              break;
            case "Data Decrescente":
              setAllItems(
                allItem.sort(
                  (b, a) =>
                    new Date(a.timestamp).getTime() -
                    new Date(b.timestamp).getTime()
                )
              );
              //    document.getElementById("app-loader").style.display = "none";
              break;
            default:
              setAllItems(allItem);
            //          document.getElementById("app-loader").style.display = "none";
          }
        });
      });
    });
  }, [files, folders, contacts, filter]);

  async function getAllFolder(arrItem) {
    const len = folders.length;
    let bool;

    for (let i = 0; i < len; i++) {
      bool = await getFolder(folders[i]).then((res) => {
        setNewFile(res);
        arrItem.push(res);
      });

      if (len - 1 === i) return bool;
    }
  }

  async function getAllFile(arrItem) {
    const len = files.length;
    let bool;

    for (let i = 0; i < len; i++) {
      bool = await getFile(files[i]).then((res) => {
        setNewFile(res);
        return arrItem.push(res);
      });

      if (len - 1 === i) return bool;
    }
  }

  async function getAllContact(arrItem) {
    const len = contacts.length;
    let bool;

    for (let i = 0; i < len; i++) {
      bool = await getContact(contacts[i]).then((res) => {
        res.name = res.referente;
        setNewFile(res);
        arrItem.push(res);
      });

      if (len - 1 === i) return bool;
    }
  }

  const updateFolder = (folderId) => {
    const index = folders.indexOf(folderId);
    const tempFolder = [...folders];
    tempFolder.splice(index, 1);
    setFolders(tempFolder);
    setFolders(addInArray(folderId, index, tempFolder));
    setTimeout(() => {
      getFolder(currentFolder._id).then((res) => {
        dispatch(currentFolderAction("cambiaCartella", {}));
        dispatch(currentFolderAction("cambiaCartella", res));
      });
    }, 200);
  };

  useEffect(() => {
    if (files.length > 0 || folders.length > 0 || contacts.length > 0) {
      setWarnDisp("none");
    } else {
      setWarnDisp("block");
    }
  }, [files, folders, contacts]);

  useEffect(() => {
    if (currentFolder.parentId) {
      setDispPath("block");
    } else {
      setDispPath("none");
    }
  }, [currentFolder]);

  useEffect(() => {
    if (currentFolder._id) {
      setFolders(currentFolder.folders);
      setFiles(currentFolder.files);
      setContacts(currentFolder.contacts);

      getFolder(currentFolder.parentId).then((res) => setParentFolder(res));
    }
  }, [currentFolder]);

  const closeTextModal = () => {
    setTextModalDisp("none");
  };

  const closeFolderModal = () => {
    setFolderModalDisp("none");
  };

  const btnClick = (text) => {
    if (text === "Cartella") {
      setFolderModalDisp("block");
    } else if (text === "File") {
      document.getElementById("load_file").click();
    } else if (text === "Contatto") {
      setContactModalDisp("block");
    }
  };

  const loadFile = (e) => {
    if (e.target.value && e.target.files.length > 0) {
      const len = e.target.files.length;
      const promiseArr = [];
      setTextModalDisp("block");

      for (let i = 0; i < len; i++) {
        promiseArr.push(saveFile(e.target.files[i], currentFolder._id));
      }

      Promise.all(promiseArr).then((res) => {
        getFolder(currentFolder._id).then((res) => {
          dispatch(currentFolderAction("cambiaCartella", {}));
          dispatch(currentFolderAction("cambiaCartella", res));
          setTextModalDisp("none");
        });
      });
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", () => {
      dispatch(dragAndDropAction(null, false, null, drag.itemId));
    });
  }, []);

  const drop = (e) => {
    if (drag.isDrag === true) {
      const rect = e.target.getBoundingClientRect();
      dispatch(
        dragAndDropAction(
          parentFolder._id,
          false,
          { top: rect.top, left: rect.left },
          drag.itemId
        )
      );
    }
  };

  return (
    <div className="Window" style={props.style}>
      <div className="window_header">
        <div className="window_header_sx">
          <p className="window_header_title">Drive</p>
          <SearchBar />
          <WindowBtn text="Cartella" handleClick={btnClick} />
          <WindowBtn text="File" handleClick={btnClick} />
          <WindowBtn text="Contatto" handleClick={btnClick} />
        </div>

        <div className="window_header_dx">
          <div className="window_header_dx_icon_container">
            <img className="window_header_dx_icon" src={shapeIcon}></img>
          </div>
          <SelectFilter />
        </div>
      </div>

      <div className="window_path">
        <p
          className="window_path_parent window_path_text"
          onClick={() =>
            dispatch(currentFolderAction("cambiaCartella", parentFolder))
          }
          style={{ display: dispPath }}
          onMouseUp={(e) => drop(e)}
          ref={backRef}
        >
          {parentFolder.name}
        </p>
        <img
          src={caret}
          alt="caret"
          width="6%"
          style={{ display: dispPath }}
        ></img>
        <p className="window_path_text">{currentFolder.name}</p>
      </div>

      <div className="window_item">
        {allItems ? (
          allItems.map((item) => {
            switch (item.type) {
              case "folder":
                return (
                  <FolderItem key={item._id} id={item._id} search={search} />
                );
              case "file":
                return (
                  <FileItem
                    key={item._id}
                    id={item._id}
                    search={search}
                    handleUpdateFolder={updateFolder}
                  />
                );
              case "contact":
                return (
                  <ContactItem
                    key={item._id}
                    id={item._id}
                    search={search}
                    handleUpdateFolder={updateFolder}
                    isDraggable={true}
                  />
                );
            }
          })
        ) : (
          <div></div>
        )}
        <p className="window_item_warning" style={{ display: warnDisp }}>
          Nessun File o Cartella
        </p>
      </div>

      <input
        type="file"
        id="load_file"
        multiple="multiple"
        style={{ display: "none" }}
        onChange={(e) => loadFile(e)}
      ></input>
      <TextModal
        text="Loading..."
        display={textModalDisp}
        handleClose={closeTextModal}
      />
      <CartellaModal display={folderModalDisp} handleClose={closeFolderModal} />
      <ContactModal
        display={contactModalDisp}
        handleClose={() => setContactModalDisp("none")}
      />
      <ImageShower />
    </div>
  );
};

export default Drive;
