const imageReducer = (state = {id: null, display: 'none'}, action) => {
    switch(action.type)
    {
        case 'changeImage':
            state = action.image;
            return state;
        default :
            return state;
    }
}

export default imageReducer;