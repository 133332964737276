import './WindowBtn.scss';

import plusIcon from '../../img/plus.svg';
import PropTypes from 'prop-types';

const WindowBtn = props => {
    return(
        <button className="WindowBtn" onClick={() => props.handleClick(props.text)}>
            <img className="window_btn_icon" src={plusIcon} alt="plus" width="16px"></img>
            <p className="window_btn_text">{props.text}</p>
        </button>
    );
}

WindowBtn.propTypes = {
    text: PropTypes.string.isRequired
}

export default WindowBtn;