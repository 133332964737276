const currentFolderReducer = (state = {}, action) => {
    switch(action.type)
    {
        case 'cambiaCartella':
            state = action.folder;
            return state;
        default :
            return state;
    }
}

export default currentFolderReducer;