import { serverHost } from "../utils/ServerHost";

export async function saveActivity(descrizione, refFile, refUser, startDate, endDate, note, color) {
    const activity = await fetch(serverHost + '/activity/', {
        method: "POST", 
        credentials : 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            desc: descrizione, 
            refFile: refFile, 
            refUser: refUser, 
            startDate: startDate, 
            endDate: endDate, 
            note: note, 
            color: color
        })
    }).then(resp => resp.json()).then(json => json);

    return activity
}

export async function getActivitiesInDate(startDate, endDate) {
    const activities = await fetch(serverHost + `/activity/filter/filter_by_date?startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json()).then(json => json);

    return activities;
}

export async function patchDate(id, endDate) {
    const activity = await fetch(serverHost + '/activity/', {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            endDate: endDate
        })
    })
    .then(res => res.json()).then(res => res);

    return activity;
}

export async function modifyActivity(id, descrizione, refFile, refUser, startDate, endDate, note, color) {
    const mod = await fetch(serverHost + '/activity/', {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            desc: descrizione, 
            refFile: refFile, 
            refUser: refUser, 
            startDate: startDate, 
            endDate: endDate, 
            note: note, 
            color: color
        })
    }).then(res => res.json()).then(json => json);

    return mod;
}

export async function deleteActivity(id) {
    const del = await fetch(serverHost + '/activity/', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id
        })
    }).then(res => res.json()).then(json => json);

    return del;
}

export async function getActivity(id) {
    const activity = await fetch(serverHost + '/activity/' + id, {credentials: 'include'})
    .then(res => res.json()).then(act => act);

    return activity;
}

export async function changeDate(id, giorni) {
    const activity = await fetch(serverHost + '/activity/changedate', {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            giorni: giorni
        })
    }).then(res => res.json()).then(json => json);

    return activity;
}