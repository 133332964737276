import './Modal.scss';
import './Modal2.scss';

import closeIcon from '../../img/closeBlue.svg';
import folderIcon from '../../img/Folder.svg';
import { useDispatch, useSelector } from 'react-redux';
import { currentFolderAction } from '../../redux/actions/currentFolder';
import WindowBtn2 from '../WindowBtn/WindowBtn2';
import { createFolder, getFolder } from '../../requests/folder';
import { useState, useEffect } from 'react';
import ClickOutside from 'react-click-outside'

const CartellaModal = props => {
    const parentId = useSelector(state => state.currentFolder._id);
    const [display, setDisplay] = useState('none');
    const [modalAnim, setModalAnim] = useState();
    const [animation, setAnimation] = useState();

    const dispatch = useDispatch();

    const salvaCartella = e => {
        if (e.code === 'Enter') {
            save();
        }
    }

    const save = () => {
        const inputText = document.getElementById('modalInput');
        const text = inputText.value;
        inputText.value = "";
        props.handleClose();
        createFolder(text, parentId).then(res => {
            getFolder(parentId).then(res => {
                dispatch(currentFolderAction('cambiaCartella', {}));
                dispatch(currentFolderAction('cambiaCartella', res));
            })
        });
    }

    useEffect(() => {
        if (props.display === 'none') {
            setAnimation('hideBG .25s ease-in forwards');
            setModalAnim('hideModal .25s ease-in forwards');
            setTimeout(() => setDisplay('none'), 300);
        } else {
            setDisplay(props.display);
            setAnimation('showBG .25s ease-in forwards');
            setModalAnim('showModal .25s ease-in forwards');
        }
    }, [props.display]);

    return (
        <div className="Modal2" style={{ display: display, animation: animation }} onClick={e => e.stopPropagation()}>
            <ClickOutside onClickOutside={() => props.handleClose()}>

                <div className="modal2_container" style={{ width: '400px', animation: modalAnim }}>
                    <div className="modal2_container_header">
                        <img src={folderIcon} alt="contact" height="60px"></img>
                        <img className="modal2_close_icon" src={closeIcon} alt="close" height="28px" onClick={() => props.handleClose()}></img>
                    </div>

                    <div style={{ marginTop: '50px' }}></div>

                    <div className="modal2_contact_text">Nome Cartella</div>
                    <input className="modal2_contact_input_text" id="modalInput" placeholder="Inserisci il nome" onKeyDown={e => salvaCartella(e)}></input>

                    <div className="modal2_contact_footer">
                        <WindowBtn2 text="Crea Cartella" handleClick={save} />
                    </div>
                </div>


            </ClickOutside>
        </div>
    );
}

export default CartellaModal;