import { useEffect, useState } from "react";

const ColorsCircleButton = props => {
    const [border, setBorder] = useState('0px solid ' + props.color);

    const dimension = "40px";

    useEffect(() => {
        if(props.color === props.selectedColor) {
            setBorder('3px solid #fff');
        } else {
            setBorder('0px solid ' + props.color);
        }
    }, [props.selectedColor, props.color]);

    return(
        <div style={{background: props.color, width: dimension, height: dimension, borderRadius: '50%', border: border, cursor: 'pointer', boxSizing: 'border-box', transition: 'all .25s ease-in'}} onClick={() => props.handleClick(props.color)}></div>
    );
}

export default ColorsCircleButton;