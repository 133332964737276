import './Notifications.scss';

import notifyIcon from '../../img/notifica.svg';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NotifyModal from '../NotifyModal/NotifyModal';

const Notifications = () => {
    const [notifiche, setNotifiche] = useState([]);
    const [readDisp, setReadDisp] = useState('block');
    const [notifyClick, setNotifyClick] = useState(false);
    const [startAnim, setStartAnim] = useState(false);
    const [fiveNotify, setFiveNotify] = useState([]);

    const socket = useSelector(state => state.socket);
    const myEmail = useSelector(state => state.isLogged);

    useEffect(() => {
        if(socket) {
            socket.on('newNotify', () => {
                socket.emit('getNotifications', myEmail);
            });
    
            socket.on('sendNotify', notify => {
                setNotifiche(notify);
            });

            socket.emit('getNotifications', myEmail);
        }
    }, [socket]);

    useEffect(() => {
        if(notifiche.length > 0) {
            setReadDisp('block');
            if(notifiche.length > 5) {
                const lastFive = [];
                for(let i=0; i<5; i++)
                {
                    lastFive.push(notifiche[i]);
                }
                setFiveNotify(lastFive);
            }
            else setFiveNotify(notifiche);
        } else {
            setReadDisp('none');
            setStartAnim(false);
        }
    }, [notifiche]);

    useEffect(() => {
        if(notifyClick) {
            setStartAnim(true);
            
        } else {
            setStartAnim(false);
        }
    }, [notifyClick]);

    return(
        <div className="Notifications">
            <img className="notifications_svg" src={notifyIcon} alt="notifiche" onClick={() => setNotifyClick(!notifyClick)}></img>
            <div className="notifications_daleggere" style={{display: readDisp}}></div>
            <div className="notification_container">
                {
                    fiveNotify.map(el => <NotifyModal type={el.type} timestamp={el.timestamp} email={el.user} start={startAnim} key={el._id} id={el._id} itemId={el.itemId}/>)
                }
            </div>
        </div>
    );
}

export default Notifications;