import './WindowItem.scss';

import icon from '../../img/Contact.svg';
import opt from '../../img/pall.svg';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentFolderAction } from '../../redux/actions/currentFolder';
import { deleteContact, getContact, changeContactFolder } from '../../requests/contact';
import ContactModalShow from '../Modal/ContactModalShow';
import DeleteModal from '../Modal/DeleteModal';
import { getFolder } from '../../requests/folder';
import ContactModalModify from '../Modal/ContactModalModify';
import { dragAndDropAction } from '../../redux/actions/dragAndDrop';
import { dropDownAction } from '../../redux/actions/dropDown';
import { Animated } from "react-animated-css";


const ContactItem = props => {
    const [contact, setContact] = useState({});
    const [disp, setDisp] = useState('none');
    const [clickedOPT, setClickedOpt] = useState(false);
    const [showList, setShowList] = useState({ width: "0px", height: "0px", fontSize: "0px" });
    const [deleteDisp, setDeleteDisp] = useState('none');
    const [showContact, setShowContact] = useState('none');
    const [showModifyContact, setShowModifyContact] = useState('none');

    const dispatch = useDispatch();
    const myEmail = useSelector(state => state.isLogged);
    const currentFolder = useSelector(state => state.currentFolder);

    const drag = useSelector(state => state.dragAndDrop);
    const dropDown = useSelector(state => state.dropDown);

    useEffect(() => {
        setDisp('none');
    }, [currentFolder]);

    useEffect(() => {
        if(dropDown === props.id) {
            setClickedOpt(true);
        } else {
            setClickedOpt(false);
        }
    }, [dropDown]);

    useEffect(() => {
        if (drag.isDrag !== true && props.isDraggable === true) {
            const item = document.getElementById(props.id);
            if(drag.folderId === null) {
                if(item.style.opacity > 0 || !item.style.opacity) {
                    item.style.cssText=`margin: 20px 20px 0px 0px;top:0;left:0;dispaly:${disp};`;
                }
            } else if (drag.itemId === props.id) {
                item.style.transition = 'all 0.25s ease-in';
                item.style.top = drag.folderPos.top - 35 + "px";
                item.style.left = drag.folderPos.left - 35 + "px";
                item.style.transform = 'scale(0.3)';
                item.style.opacity = '0';
                changeContactFolder(props.id, contact.folderId, drag.folderId).then(res => {
                    if (props.handleUpdateFolder) props.handleUpdateFolder(drag.folderId);
                });
            }
        }
    }, [drag]);

    useEffect(() => {
        getContact(props.id).then(contatto => {
            setContact(contatto);
            if(contatto.folderId === currentFolder._id) {
                setDisp('block');
            }
        });
    }, [props.id]);

    useEffect(() => {
        if (clickedOPT) {
            setShowList({ width: "170px", height: "125px", fontSize: "16px" });
        }
        else {
            setShowList({ width: "0px", height: "0px", fontSize: "0px" });
        }
    }, [clickedOPT]);

    const renameClick = e => {
        e.stopPropagation();
        dispatch(dropDownAction(null));
        setShowModifyContact('block');
    }

    const deleteClick = e => {
        e.stopPropagation();
        dispatch(dropDownAction(null));
        setDeleteDisp('block');
    }

    const optClick = e => {
        if (dropDown === props.id) {
            dispatch(dropDownAction(null));
        } else {
            dispatch(dropDownAction(props.id));
        }
        e.stopPropagation();
    }

    const del = () => {
        deleteContact(props.id).then(res => {
            getFolder(currentFolder._id).then(res => {
                dispatch(currentFolderAction('cambiaCartella', {}));
                dispatch(currentFolderAction('cambiaCartella', res));
                setDeleteDisp('none');
            });
        });
    }

    const mod = (agName, ref, email, number, note) => {
        setContact({
            agencyName: agName,
            referente: ref,
            email: email,
            number: number,
            note: note,
            creator: myEmail
        });
    }

    const sposta = e => {
        dispatch(dragAndDropAction(null, true, null, props.id));
        document.addEventListener('mouseup', relaseItem, { once: true });
        document.addEventListener('mousemove', followMouse);
    }

    const followMouse = e => {
        const item = document.getElementById(props.id);
        item.style.cssText=`position:fixed;top:${e.clientY}px;left:${e.clientX}px;z-index:10000;margin:0px;dispaly:${disp};`;
    }

    const relaseItem = () => {
        document.removeEventListener('mousemove', followMouse);
        document.removeEventListener('mouseup', relaseItem);
    }
    
    return (
        <>
            <div className="WindowItem" id={props.id} onMouseDown={e => { if (props.isDraggable) sposta(e); }} onDoubleClick={() => { if (props.isDraggable) setShowContact('block'); }} onClick={() => { if (!props.isDraggable) setShowContact('block'); }} style={{ display: disp }}>
                <div className="window_item_svg">
                    <img className="window_item_svg_icon" src={icon} alt="item_icon"></img>
                    <div className="window_item_row">
                        <p className="window_item_row_text">{contact.referente}</p>
                    </div>

                    <div className="window_item_row">
                        <p className="window_item_row_text2">{contact.number}</p>
                    </div>
                </div>

                <img className="window_item_opt" id="windowItemOpt" src={opt} alt="options" onClick={e => optClick(e)}></img>
                <div className="window_item_opt_list" id="windowItemOptList" style={showList}>
                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn1" onClick={e => deleteClick(e)}>Elimina</p>
                    <p className="window_item_opt_list_btn" id="windowItemOptListBtn2" onClick={e => renameClick(e)}>Modifica</p>
                </div>


            </div>
            <ContactModalShow id={props.id + ((props.isDraggable) ? '32asr' : '33ertev')} agencyName={contact.agencyName} referente={contact.referente} number={contact.number} note={contact.note} email={contact.email} display={showContact} handleClose={() => setShowContact('none')} />
            <DeleteModal handleClose={() => setDeleteDisp('none')} display={deleteDisp} handle={del} />
            <ContactModalModify idContact={props.id} id={props.id + ((props.isDraggable) ? '322as' : '33vertve')} agencyName={contact.agencyName} referente={contact.referente} number={contact.number} note={contact.note} email={contact.email} display={showModifyContact} handleClose={() => setShowModifyContact('none')} handleModify={mod} />
        </>
    );
}

export default ContactItem;