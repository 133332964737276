export function separateNameAndExt(completeName) {
    const len = completeName.length;
    const from = completeName.lastIndexOf('.');
            
    let ext = "";
    let name = "";

    for(let i=0; i<from; i++)
    {
        name += completeName.charAt(i);
    }

    for(let i=from+1; i<len; i++)
    {
        ext += completeName.charAt(i);
    }

    return [name, ext];
}

export function addZeroToDateNumber(number) {
    if(number < 10)
        return '0' + number;
    else return number;
}

export function addInArray(item, pos, arr) {
    const newArr = [];
    const len = arr.length;

    for(let i=0; i<pos; i++) {
        newArr.push(arr[i]);
    }

    newArr.push(item);

    if(pos - 1 !== len) {
        for(let i=pos; i<len; i++) {
            newArr.push(arr[i]);
        }
    }

    return newArr;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Il max è escluso e il min è incluso
}

export function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

export function removePxFromString(str) {
    const len = str.length;
    let newStr = '';

    for(let i=0; i<len-2; i++) {
        newStr += str.charAt(i);
    }

    return Number(newStr);
}

export const colors = ['#CB2040', '#DA802E', '#7BC2EA', '#5CBC83', '#FFC6E7'];